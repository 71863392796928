import request from '../utils/request';

export function accountList(listId,query) {
	return request({
		url: "whitelist/account_list?id="+listId,
		method: "get",
		params: query
	});
}

export function addList(listId,query) {
	return request({
		url: "whitelist/account_read?id="+listId,
		method: "get",
		params: query
	});
}

// export function accountDelete(query) {
//     return request({
//         url: 'whitelist/account_delete'+query,
//         method: 'delete',
//         // data: query
//     });
// }
export function accountDelete(query) {
    return request({
        url: 'whitelist/account_delete',
        method: 'POST',
        data: query
    });
}

export function accountlistSave(data) {
	return request({
		url: "whitelist/account_add",
		method: "post",
		data: data
	});
}