<template>
	<div class="conbox">
		<div class="sub_til" style="margin-top: 20px;">网络白名单-账号管理</div>
		<div style="margin: 15px 0; ">
			<el-button type="primary" @click="handleForm(null, null)" size="mini">新增账号</el-button>
		</div>
		<el-table v-loading="loading" :data="list" style="width: 100%" max-height="500" border>
			<el-table-column label="账号" prop="account"></el-table-column>
			<el-table-column label="创建时间" prop="create_time"> </el-table-column>
			<el-table-column label="到期日期" prop="expiration_time"> </el-table-column>
			<el-table-column label="操作" fixed="right">
				<template #default="scope">
					<el-button type="text" size="small" icon="el-icon-delete" class="red"
						@click="handleDel(scope.$index, scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!--新增弹窗内容-->
		<el-dialog :title="formTil" v-model="formVisible" :before-close="hideForm" width="85%" top="5vh">
			<el-form :model="formData" :rules="formRules" ref="dataForm">
				<el-form-item label="账号" prop="account">
					<el-input v-model="formData.account" auto-complete="off"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input v-model="formData.password" auto-complete="off"></el-input>
				</el-form-item>
				<el-form-item label="到期时间" prop="expiration_time">
					<el-input v-model="formData.expiration_time" auto-complete="off" :disabled="true"></el-input>
				</el-form-item>
			</el-form>
			<div class="dialog-footer">
				<el-button @click="hideForm">取消</el-button>
				<el-button type="primary" @click="formSubmit()" :loading="formLoading">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		accountList,
		addList,
		accountlistSave,
		accountDelete
	} from "../api/accountlist";
	const formJson = {};
	export default {
		data() {
			return {
				list: [],
				loading: true,
				formTil: "新增",
				formLoading: false,
				formVisible: false,
				formData: formJson,
				formRules: {
					account: [{
						required: true,
						message: "请输入账号",
						trigger: "blur"
					}],
					password: [{
						required: true,
						message: "请输入密码",
						trigger: "blur"
					}]
				},
				deleteLoading: false,
			};
		},
		methods: {
			getList() {
				this.loading = true;
				accountList(this.params.id, this.query)
					.then((res) => {
						this.loading = false;
						// console.log(res);
						this.list = res.data || [];
					})
					.catch(() => {
						this.loading = false;
						this.list = [];
					});
			},
			getaddList() {
				this.loading = true;
				addList(this.params.id, this.query)
					.then((res) => {
						this.loading = false;
						console.log(res)
						this.formData.account = res.data.account;
						this.formData.expiration_time = res.data.expiration_time;
					})
					.catch(() => {
						this.loading = false;
					});
			},
			// 隐藏表单
			hideForm() {
				// 更改值
				this.formVisible = !this.formVisible;
				// 清空表单
				this.$refs["dataForm"].resetFields();
				return true;
			},
			// 显示表单
			handleForm(index, row) {
				this.formVisible = true;
				this.formData = JSON.parse(JSON.stringify(formJson));
				if (row !== null) {
					this.formData = Object.assign({}, row);
				}
				this.getaddList();
			},
			formSubmit() {
				this.$refs["dataForm"].validate((valid) => {
					if (valid) {
						this.formLoading = true;
						// let data = Object.assign({}, this.formData);
						let data2 = {
							id: this.params.id,
							account: this.formData.account,
							password: this.formData.password
						};						
						accountlistSave(data2, this.formName).then((res) => {
							this.formLoading = false;
							if (res.code != 200) {
								this.$message.error(res.msg);
								return false;
							}
							this.$message.success("操作成功");
							this.formVisible = false;
							this.getList();
						});
					}
				});
			},
			// 删除
			handleDel(index, row) {
				this.$confirm("您确定要删除该账号吗？", "提示", {
						type: "warning",
					})
					.then(() => {
						accountDelete({id:row.id}).then((res) => {
							// console.log(res);
							if (res.code == 200) {
								this.$message.success(res.msg);
								this.getList();
								return;
							} else {
								this.$message.error(res.msg);
								return;
							}
						});
					})
					.catch(() => {});
			},
		},
		mounted() {},
		created() {
			// 加载表格数据
			this.params = this.$route.query
			this.getList();
		},
	};
</script>

<style>
</style>
